import { FileOpener } from '@capacitor-community/file-opener';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Device } from '@capacitor/device';

/**
 * Get the data dirEntry
 *
 * @returns {Promise<DirectoryEntry>}
 */
const getDataDirEntry = () => {
  return new Promise((resolve, reject) => {
    /**
     * @param {DirectoryEntry} dataDirEntry
     */
    const handleSuccess = (dataDirEntry) => {
      dataDirEntry.getDirectory(
        'debatdirect',
        { create: true, exclusive: false },
        (dirEntry) => {
          resolve(dirEntry);
        },
        (error) => reject(error),
      );
    };

    const handleError = (error) => reject(error);

    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, handleSuccess, handleError);
  });
};

/**
 * Clear the temp data directory
 * @returns {Promise}
 */
export const clearTmpDirectory = () =>
  new Promise((resolve, reject) => {
    return getDataDirEntry().then((dirEntry) => {
      dirEntry.removeRecursively(resolve, reject);
    });
  });

export const deleteFile = (filePath) => Filesystem.deleteFile({ path: filePath });

/**
 * Open a file using the file opener plugin
 *
 * @param filePath
 * @param mimeType
 * @returns {Promise}
 */
export const openFile = async (filePath, mimeType) =>
  FileOpener.open({
    filePath,
    contentType: mimeType,
    openWithDefault: false, // iOS: Open Share menu. Android: Open file directly
  });

/**
 * Download file to the local device
 * @param url
 * @param fileName
 * @returns {Promise<filePath>}
 */
export const downloadUrl = async (url, fileName) => {
  if (!window.cordova) {
    throw new Error('Unsupported platform feature');
  }

  // Circumvent storage permissions for legacy Android, by saving to data-directory
  const info = await Device.getInfo();
  const androidLegacy = info.platform === 'android' && Number(info.osVersion) <= 10;
  const directory = androidLegacy ? Directory.Data : Directory.Documents;

  try {
    // File exist check. Will throw error when not exist
    const statResult = await Filesystem.stat({
      path: fileName,
      directory,
    });
    return statResult.uri;
  } catch (e) {
    // silently continue
  }

  const fileResult = await Filesystem.downloadFile({
    path: fileName,
    recursive: true,
    directory,
    url,
  });

  return fileResult.path;
};

/**
 * Check if a file exists
 * @param filePath
 * @returns {Promise<Boolean>}
 */
export const fileExists = async (filePath) => {
  try {
    await Filesystem.stat({ path: filePath });
    return true;
  } catch (e) {
    return false;
  }
};
