import { browserHistory, hashHistory } from 'react-router';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import type { DebateLocation } from '@debatdirect/typings';

const history = window.cordova ? hashHistory : browserHistory;

export const debateUrl = (debate: AppAgendaDebate, location: DebateLocation | undefined) => {
  const params = {
    date: debate.debateDateStr,
    category: debate.categoryIds.length === 0 ? 'overig' : debate.categoryIds[0],
    location: location?.slug ?? '',
    debate: debate.slug,
  };

  const path = [params.date, params.category, params.location, params.debate, 'onderwerp'].filter(Boolean).join('/');

  return `/${path}`;
};

export const navigate = (path: string) => history.push(path);
